import React, { Component } from 'react';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import PageTransition from 'gatsby-plugin-page-transitions';
import Slider from "react-slick";
import { TimelineMax, TweenMax, Power1 } from 'gsap'
import 'intersection-observer';
import { InView } from 'react-intersection-observer'
import { ArrowLink } from "../components/buttons/Arrow_Link.js";
import SliderButton from "../components/buttons/Slider_Button.js";
import Navigation from "../components/Header/Navigation.js";
import LazyLoad from "../components/repeating_elements/Lazy_Load.js";
import BackgroundImage from "../components/repeating_elements/Background-Image.js";
import Image from "../components/repeating_elements/Image.js";
import WorkWithUs from "../components/Global/Work_With_Us_Button.js";
import GDPR from '../components/Global/GDPR';
import SEO from '../components/seo.js';
import PageBreak from "../components/repeating_elements/page_break.js";
import GetInTouchFooter from "../components/Global/Get_In_Touch_Footer.js";
import Footer from "../components/Footer/Footer.js";
import BasicHero from "../components/Global/Basic_Hero.js";
import PeripheralList from "../components/repeating_elements/Peripheral-List.js";
import Listing from "../components/Service/Listing.js";
import { split } from '../components/utilities/split.js';
import BrowserView from "../components/utilities/mobileViewCheck.js";

export default class About_Page extends Component {
	constructor(props){
		super(props);
		this.state = {
			isLoaded: this.props.pageContext.isLoaded,
			isLazyLoaded: this.props.pageContext.isLazyLoaded,
			isReady: this.props.pageContext.isReady,
			data: this.props.pageContext.data,
			cookieBanner: this.props.pageContext.cookieBanner,
			headerLinks: this.props.pageContext.header,
			footerLinks: this.props.pageContext.footer,
			featured_case_studies: false,
			award_slider_settings: {
				adaptiveHeight: false,
				arrows: true,
				dots: false,
				infinite: false,
				speed: 500,
				slide: ".slide",
				slidesToShow: 1,
				slidesToScroll: 1,
				prevArrow: <SliderButton className="slick-next" />,
				nextArrow: <SliderButton className="slick-prev" />
			}
		}
	}
	componentDidMount() {
	}
	render_listing(){
		if(this.state.data){
			return this.state.data[0].acf.pages.map( (item) =>{
				return <Listing key={item.title} title={item.title} description={item.description} image={item.image.sizes.large} url={item.page_link} />;
			});
		}
	}
	handleInView(inView) {
		TweenMax.set(this.description, { y: '70px', opacity: 0 })
		if (inView) {
			const timelime = new TimelineMax()
			timelime
				.to(this.description, .4, { y: '0%', opacity: 1, ease: Power1.easeInOut }, '.7')
		}
	}
	handleAwardInView(inView) {
		const awardTitleChars = split(this.awardTitle)
		const slides = document.querySelectorAll('.award-list .item')
		TweenMax.staggerTo(awardTitleChars, 0, { y: '120%' })
		TweenMax.staggerTo(slides, 0, { y: '120px', opacity: 0 })
		if (inView) {
			const timelime = new TimelineMax()
			timelime
				.staggerTo(awardTitleChars, .4, { y: '0%', ease: Power1.easeInOut }, '.015', '.4')
				.staggerTo(slides, .4, { y: '0px', opacity: 1, ease: Power1.easeInOut }, '.2', '.4')
		}
	}
	render() {
		let page_ready = this.state.isLoaded && this.state.isReady ? "page-ready page-loaded show-text" : "page-loading";
		let data = this.state.data;
		if(data){
			data = this.state.data[0].acf;
			return (
				<ParallaxProvider><PageTransition>
					<GDPR data={this.state.cookieBanner} />
				<SEO
					title={this.state.data[0].yoast_meta.yoast_wpseo_title}
					siteUrl={this.state.data[0].yoast_meta.yoast_wpseo_canonical}
					description={this.state.data[0].yoast_meta.yoast_wpseo_metadesc}
				/>
					<div className="site-contain">
						<Navigation links={this.state.headerLinks} />
          				<div className="page-main">
					<WorkWithUs />
					<div className={"page about-page " + page_ready}>
						<BasicHero className="large">
							<div className="imagery in-view">
								<div className="image mobile-image">
									<BackgroundImage url={this.state.data[0].acf.mobile_hero_image} mobileURL={this.state.data[0].acf.desktop_hero_image} cover="disabled" loading="eager"></BackgroundImage>
								</div>
								<div className="video">
									<BrowserView video={this.state.data[0].acf.hero_video} cover="disabled" />
								</div>
							</div>
						</BasicHero>
						<div className="grid">
							<InView as="div" triggerOnce={true} onChange={inView => this.handleInView(inView)} >
								<div className="grid-m-6 grid-t-12 grid-d-10 description-container">
									<p className="styling-header-2" ref={c => this.description = c}>{data.description}</p>
								</div>
							</InView>
							<LazyLoad>
								<div className="row legacy-container">
									<div className="grid-m-6 grid-t-8 grid-d-6 legacy-text">
										<h2 className="styling-eyebrow">{data.legacy_eyebrow}</h2>
										<h3 className="styling-header-2">{data.legacy_header}</h3>
										<p>{data.legacy_description}</p>
									</div>
									<div className="grid-m-6 push-t-2 grid-t-6 push-d-2 grid-d-8 legacy-image">
										<Image url={data.legacy_portrait} alt={data.legacy_header} />
									</div>
								</div>
							</LazyLoad>
						</div>
						<PageBreak />
						<LazyLoad>
							<div className="grid">
								<div className="row featured-peripheral-container">
									<div className="grid-m-hide grid-t-10 grid-d-10 desktop-image">
										<a href={data.featured_peripheral_cta_url} alt={data.featured_peripheral_description} title={data.featured_peripheral_description} target="_blank" rel="noopener noreferrer">
											<Image url={data.featured_peripheral_desktop_image} />
										</a>
									</div>
									<div className="grid-m-6 grid-t-hide grid-d-hide mobile-image">
										<a href={data.featured_peripheral_cta_url} alt={data.featured_peripheral_description} title={data.featured_peripheral_description} target="_blank" rel="noopener noreferrer">
											<Image url={data.featured_peripheral_mobile_image} />
										</a>
									</div>
									<div className="grid-m-6 grid-t-6 grid-d-6 description">
										<Image url={data.featured_peripheral_logo }alt={data.featured_peripheral_description} />
										<p>{data.featured_peripheral_description}</p>
										<ArrowLink title={data.featured_peripheral_cta_copy} url={data.featured_peripheral_cta_url} />
									</div>
								</div>
							</div>
						</LazyLoad>
						<PageBreak />
						<LazyLoad>
							<div className="grid list peripheral-list-title">
								<div className="grid">
								<h5 className="styling-eyebrow grid-m-6 grid-t-hide grid-d-hide">Businesses</h5>
								</div>
								<PeripheralList slides={data.peripherals} />
							</div>
						</LazyLoad>
						<PageBreak />
						<InView as="div" triggerOnce={true} onChange={inView => this.handleAwardInView(inView)} >
							<div className="list award-list">
								<div className="grid">
									<h3 className="grid-m-6 grid-t-16 grid-d-16 styling-eyebrow" ref={c => this.awardTitle = c}>Awards &amp; Recognition</h3	>
								</div>
								<Slider {...this.state.award_slider_settings}>
									{
										(()=>{
											let elements = [];
											let awards_slides = [];
											let slide_amount = 6;
											data.awards.forEach((i,x)=>{
												if(x % slide_amount === 0){
													make_slide()
												}
												awards_slides[awards_slides.length-1].push(
													<div key={"award-"+x} className="grid-m-3 grid-t-1-3 grid-d-1-3 item">
														<div className="item-logo">
															<Image url={i.item_logo} alt={i.item_name} />
														</div>
														<div className="item-copy">
															<p className="name">{i.item_name}</p>
															<p className="title">{i.item_title}</p>
														</div>
													</div>
												);
											})
											function make_slide(){
												awards_slides.push([]);
											}
											awards_slides.forEach((i,x)=>{
												elements.push(<div key={"award-slide-"+x} className="grid slide">{i}</div>);
											});
											return elements;
										})()
									}
								</Slider>
							</div>
						</InView>
						<PageBreak />
						<LazyLoad>
							<div className="grid press-grid">
								<h3 className="grid-m-6 grid-t-16 grid-d-16 styling-eyebrow">Press</h3>
									{
										(()=>{
											let large_press_items = [];
											let small_press_items = [];
											data.press_items.forEach((i,x)=>{
												var classnames = "";
												var image_element;
												if(x < 2){
													classnames+="grid-m-6 grid-t-5 grid-d-5 press-item";
													image_element = <a href={i.url} target="_blank" rel="noopener noreferrer"><div className="img"><BackgroundImage url={i.image} /></div></a>;
												} else{
													classnames+="press-item";
												}
												var element = <div key={x} className={classnames}>
													{ image_element }
													<p className="title"><a href={i.url} target="_blank" rel="noopener noreferrer" title={i.title}>{i.title}</a></p>
													<p className="body-copy">{i.description}</p>
												</div>;
												if(x < 2){
													large_press_items.push(element)
												} else{
													small_press_items.push(element)
												}
											})
											return <React.Fragment>
												{large_press_items}
												<div className="grid-m-6 grid-t-6 grid-d-6 press-col">
													{small_press_items}
												</div>
											</React.Fragment>;
										})()
									}
							</div>
						</LazyLoad>
						<PageBreak />
						<GetInTouchFooter />
						<Footer links={this.state.footerLinks} />
					</div>
					</div>
				</div>
			</PageTransition></ParallaxProvider>
			);
		} else{

			return (
				<div></div>
			);
		}
	}
}
