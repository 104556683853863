import React from "react";
import FullArrow from "../../assets/svg/full-arrow.svg";

export default function Slider_Button(props) {
	const { className, onClick } = props;
	return (
		<button
			className={className}
			onClick={onClick}>test
			<FullArrow />
		</button>
	)
}
